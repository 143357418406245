import React, { useRef, useState, useEffect } from 'react'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import InputAdornment from '@material-ui/core/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'
import SearchIcon from '@material-ui/icons/Search'
import grey from '@material-ui/core/colors/grey'
import { apiGetWithParams } from '../../../../api'
import { urlDevices } from '../../../../api/urls'
import { TextField } from '@material-ui/core'
import { sendAlert } from '../../../../actions/globalAlert/sendAlert'
import { useDispatch } from 'react-redux'


/**
 * 
 * @param setQueryResult Function to set the result of the search query 
 * @returns 
 */
export default function SearchInput({ setQueryResult }) {
	var themeContext = useTheme()

	const textTimer = useRef(null)
	const [ text, setText ] = useState('')
	const [ focus, setFocus ] = useState(false)
	const [ loading, setLoading ] = useState(false)
	const [ showWarining, setShowWarining ] = useState(false)

    const dispatch = useDispatch()

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const handleSearch = query => {
		if (query.length > 3) {
			setShowWarining(false)
			setLoading(true)
			apiGetWithParams(urlDevices, { q: query })
				.then(r => {
					console.log(r)
					if (r.status === 'OK') setQueryResult(r.data)
                    else dispatch(sendAlert('Error: no se pudo concretar la busqueda', 'error'))
				})
				.catch(error => console.log('Catch error', error))
				.finally(() => {
					setLoading(false)
				})
		} else {
			if (query) {
				setShowWarining(true)
			} else {
				setShowWarining(false)
			}
		}
	}

	useEffect(
		() => {
			clearTimeout(textTimer.current)
			textTimer.current = setTimeout(() => handleSearch(text), 700)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[ text ]
	)

	return (
		<Box>
				<TextField
					id='search-bar'
					type='search'
					value={text}
                    color='primary'
                    placeholder='Buscar por ID'
                    variant='outlined'
					onChange={e => setText(e.target.value)}
					onBlur={e => setFocus(false)}
					onFocus={e => setFocus(true)}
                    fullWidth
					InputProps={{startAdornment: 
						<InputAdornment position='start'>
							{loading ? (
								<Box
									component='div'
									style={{
										width: 24,
										height: 24,
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<CircularProgress size={19} />
								</Box>
							) : (
								<SearchIcon
									style={{
										color: focus ? themeContext.palette.primary.main : grey[600],
									}}
								/>
							)}
						</InputAdornment>
					}}
                    helperText={showWarining? 'Especifique más caracteres' : undefined}
				/>
		</Box>
	)
}
