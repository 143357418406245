import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Dashboard from './Components/Dashboard'
import Login from './Components/Login'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import ThemeConfig from './utils/theme'
import GlobalAlert from './utils/GlobalAlert'
import { ProvideAuth } from './utils/reactRouter/authContext'
import { apiPost } from './api'
import { urlLogin } from './api/urls'
import { setLogin } from './actions/login/setLogin'
import { Backdrop, CircularProgress } from '@material-ui/core'

export default function App() {
    const [loading, setLoading] = useState(true)
    const token = useSelector(state => state.login.token)
    const open = useSelector(state => state.feedback.loading)
    const dispatch = useDispatch()

    useEffect(() => {
        const token = localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_USER_TOKEN)
        if (token) {
            apiPost(urlLogin, { token })
                .then(r => {
                    if (r.status === 'OK') {
                        dispatch(setLogin(r.data))
                    }
                    setLoading(false)
                })
                .catch(e => {
                    setLoading(false)
                    console.log(e)
                })
        } else {
            setLoading(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <ThemeConfig>
            <GlobalAlert />
            <ProvideAuth>
                <Router>
                    <Backdrop
                        style={{ zIndex: 9999 }}
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={open > 0}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Switch>
                        {token ? (
                            <Route path='/'>
                                <Dashboard />
                            </Route>
                        ) : (
                            <Route path='/'>
                                <Login ready={!loading} />
                            </Route>
                        )}
                    </Switch>
                </Router>
            </ProvideAuth>
        </ThemeConfig>
    )
}
