import { Box, makeStyles } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router'
import AppManagement from './AppManagement'
import Location from './Location'
import NavBar from './NavBar'
import News from './News'
import Overview from './Overview'
import WebSites from './WebSites'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		height: '100vh'
	},
	main: {
		flexGrow: 1,
		height: '100%',
		//padding: theme.spacing(3),
		width: drawerWidth => 'calc(100vw - ' + drawerWidth + 'px)',
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
    content: {
        height: 'calc(100% - 64px)',
        overflowY: 'auto',
        display: 'flex'
    },
}))

/**
 * A component that render the main screen for each route
 */
export default function Dashboard() {
    const drawerWidth = useSelector(state => state.feedback.drawerWidth)
	const classes = useStyles(drawerWidth)

	return (
		<Box className={classes.root}>
			<NavBar />
			<main className={classes.main}>
				<div className={classes.toolbar} />
				<div className={classes.content}>
					<Switch>
						<Route path='/Dashboard'>
							<Overview />
						</Route>
						<Route path='/location'>
							<Location />
						</Route>
						<Route path='/blocked-websites'>
							<WebSites />
						</Route>
						<Route path='/app-management'>
							<AppManagement />
						</Route>
						<Route path='/news-management'>
							<News />
						</Route>
						<Route path='/'>
							<Redirect to='/Dashboard' />
						</Route>
					</Switch>
				</div>
			</main>
		</Box>
	)
}
