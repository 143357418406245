// material
import { Grid, Container, CircularProgress, Typography, Backdrop } from '@material-ui/core'
import { useEffect, useState } from 'react'
// components
import {
    AppNewDevices,
    AppBugReports,
    AppPendingTickets,
    AppTotalDevices,
    AppDevicesPerVersion,
    AppNewDevicesPerDay,
} from './charts'
import { apiGet } from '../../../api'
import { urlStats } from '../../../api/urls'

// ----------------------------------------------------------------------

export default function DashboardApp() {
    const [data, setData] = useState({ error: false })
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        apiGet(urlStats).then(r => {
            if (r.status === 'OK') {
                setData({ ...r.data, error: false })
            } else {
                setData({ error: true })
            }
            setLoading(false)
        }).catch(e => {
            setData({ error: true })
            setLoading(false)
        })
    }, [])

    return (loading ? <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
    >
        <CircularProgress color="inherit" />
    </Backdrop>
        : data.error ? <Typography>No es posible acceder a las estadisticas en este momento</Typography> : <Container maxWidth="xl" style={{ padding: '1rem' }}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={3}>
                    <AppTotalDevices data={data.totalDevices} />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <AppNewDevices data={data.newDevices}/>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <AppPendingTickets />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <AppBugReports />
                </Grid>
                <Grid item xs={12} md={6} lg={8}>
                    <AppNewDevicesPerDay data={data.newDevicesByDay} />
                </Grid>

                <Grid item xs={12} md={6} lg={4}>
                    <AppDevicesPerVersion data={data.devicesByVersion} />
                </Grid>
            </Grid>
        </Container>
    )
}