import React, { useEffect, useState } from 'react'
import { Box, makeStyles, TextField, Fab, Typography } from '@material-ui/core'
import WebSiteItem from './WebSiteItem'
import { AddRounded } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(2),
        flex: 1
    },
    top: {
        //height: 100,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        marginBottom: theme.spacing(2)
    },
    content: {
        flex: 1,
        display: 'flex',
        alignContent: 'flex-start',
        flexWrap: 'wrap',
        alignItems: 'stretch',
    },
    fab: {
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        position: 'fixed'
    }
}))

/** A component representing the web sites screen */
export default function WebSites() {
    const [search, setSearch] = useState('')
    const [sites, setSites] = useState([])
    const classes = useStyles()

    useEffect(() => {
        //Get sites 
        setSites([
            { url: 'http://google.com', title: 'Google' },
            { url: 'http://facebook.com', title: 'Facebook' },
            { url: 'http://instagram.com', title: 'Instagram' },
            { url: 'http://twitter.com', title: 'Twitter' },
            { url: 'https://webbrainsmedia.com/blogs/how-to-create-a-floating-action-button-using-material-ui-in-react', title: 'Web brains media' },
        ])
    }, [])

    return <Box className={classes.root}>
        <Fab className={classes.fab} color='primary' variant='extended'>
            <AddRounded /> Agregar sitio
        </Fab>
        <Box className={classes.top}>
            <Typography style={{ marginRight: '1rem' }} variant='h5'>Bloqueo de WebSites</Typography>
            <TextField
                name='seach'
                value={search}
                label='Buscar'
                variant='outlined'
                margin='dense'
                onChange={({ target: { value } }) => setSearch(value)}
            />
        </Box>
        <Box className={classes.content}>
            {sites.map((site, i) => site.url.toLowerCase().indexOf(search.toLowerCase()) !== -1 ?
                <WebSiteItem {...site} key={'site-item-' + i} /> : null
            )}
        </Box>
    </Box>
}