import React, { useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'

import LoadingButton from './LoadingButton'
import { apiPost } from '../../api'
import { urlLogin } from '../../api/urls'
import { useDispatch } from 'react-redux'
import { sendAlert } from '../../actions/globalAlert/sendAlert'
import { setLogin } from '../../actions/login/setLogin'
import { CircularProgress } from '@material-ui/core'

const Copyright = () => {
	return (
		<Typography variant='body2' color='textSecondary' align='center'>
			{'Copyright © '}
			{'WeDrim ' + new Date().getFullYear()}
			{'.'}
		</Typography>
	)
}

const useStyles = makeStyles(theme => ({
	root: {
		marginTop: '1rem',
		alignItems: 'center',
	},
	button: {
		color: 'white',
		fontWeight: 'bold',
	},
	mainContent: {
		marginTop: 50,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		[theme.breakpoints.down('sm')]: {
			minWidth: 'unset',
		},
		[theme.breakpoints.up('sm')]: {
			minWidth: 475,
		},
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'white',
		paddingLeft: 30,
		paddingRight: 30,
		paddingBottom: 40,
		paddingTop: 40,
		borderRadius: 20,
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.primary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
}))

const Login = ({ ready }) => {
	const classes = useStyles()
	const [ username, setUsername ] = useState({ value: '', hasError: false, errorMessage: '' })
	const [ password, setPassword ] = useState({ value: '', hasError: false, errorMessage: '' })
	const [ loading, setLoading ] = useState(false)
	const dispatch = useDispatch()

	const validateUsername = () => {
		if (username.value === '') {
			setUsername(ps => ({ ...ps, hasError: true, errorMessage: 'Ingrese usuario.' }))
			return false
		} else {
			setUsername(ps => ({ ...ps, hasError: false, errorMessage: '' }))
			return true
		}
	}
	const validatePassword = () => {
		if (password.value === '') {
			setPassword(ps => ({ ...ps, hasError: true, errorMessage: 'Ingrese su contraseña.' }))
			return false
		} else {
			setPassword(ps => ({ ...ps, hasError: false, errorMessage: '' }))
			return true
		}
	}

	const handleButtonClick = () => {
		if (validateUsername() & validatePassword() && !loading) {
			setLoading(true)
			apiPost(urlLogin, { username: username.value, password: password.value })
				.then(r => {
					if (r.status === 'OK') {
						dispatch(setLogin(r.data))
					} else if (r.status === 'INCORRECT') dispatch(sendAlert('Usuario o contraseña incorrecto', 'info'))
					else dispatch(sendAlert('Algo salio mal', 'error'))
					setLoading(false)
				})
				.catch(err => {
					setLoading(false)
					dispatch(sendAlert(err.message, 'error'))
				})
		}
	}

	const onKeyPress = event => {
		if (event.code === 'Enter' || event.code === 'NumpadEnter') handleButtonClick()
	}

	return (
		<Container component='main' maxWidth='xs' className={classes.mainContent}>
			<div className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component='h1' variant='h5'>
					Ingreso
				</Typography>
				{!ready ? (
					<CircularProgress color='primary' />
				) : (
					<form className={classes.form} noValidate>
						<TextField
							error={username.hasError && !username.value}
							helperText={username.errorMessage}
							variant='outlined'
							margin='normal'
							fullWidth
							id='username'
							label='Usuario'
							name='username'
							inputProps={{
								'aria-label': 'Usuario',
							}}
							value={username.value}
							onKeyPress={onKeyPress}
							onChange={({ target: { value } }) => setUsername(ps => ({ ...ps, value }))}
                            autoFocus
						/>
						<TextField
							error={password.hasError && !password.value}
							helperText={password.errorMessage}
							variant='outlined'
							margin='normal'
							fullWidth
							name='password'
							label='Contraseña'
							onKeyPress={onKeyPress}
							value={password.value}
							onChange={({ target: { value } }) => setPassword(ps => ({ ...ps, value }))}
							type='password'
							id='password'
							autoComplete='current-password'
						/>
						<LoadingButton
							loading={loading}
							fullWidth
							variant='contained'
							color='primary'
							onClick={handleButtonClick}
							className={classes.button}
						>
							Ingresar
						</LoadingButton>
					</form>
				)}
				<Box mt={2}>
					<Copyright />
				</Box>
			</div>
		</Container>
	)
}

export default Login
