import React from 'react'
import { Button } from '@material-ui/core'
import { DescriptionRounded, ImageRounded, SubjectRounded, YouTube } from '@material-ui/icons'
import formatDate from '../../../utils/formatDate'
import { green, orange, purple, red } from '@material-ui/core/colors'

/**
 * Represents a news item
 * @param news The news to render 
 * @param onClick Function to trigger when news is clicked 
 * @returns 
 */
export default function NewsItem({ news, onClick }) {

    const renderIcon = () => {
        switch (news.type) {
            case 'image':
                return <ImageRounded htmlColor={purple[400]} />
            case 'document':
                return <DescriptionRounded htmlColor={orange[400]} />
            case 'video':
                return <YouTube htmlColor={red[400]} />
            case 'text':
            default:
                return <SubjectRounded htmlColor={green[400]} />
        }
    }

    return <Button onClick={() => onClick(news)}>
        {renderIcon()} {formatDate(new Date(news.date))} | {news.title}
    </Button>
}
