import React from 'react'
import { Button, makeStyles, Paper, Typography, useTheme } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    }
}))

/**
 * 
 * @param type The type of file to select 
 * @param error If there is an empty field in the form 
 * @param data The selected file 
 * @param onChange Function to execute when a new file is selected by user 
 * @param title The title of the selector 
 * @param inputId The id to invoke the hidden input 
 * @param openFileSelector The function to open the system file selector 
 * @param accept Type of valid files
 * @returns 
 */
export default function FileSelector({ type, error, data, onChange, title, inputId, openFileSelector, accept }) {
    const classes = useStyles()
    const theme = useTheme()

    return <Paper
        variant='outlined'
        className={classes.container}
        style={{ borderColor: error && !data ? theme.palette.error.main : undefined }}
    >
        <input
            id={inputId}
            style={{ display: 'none' }}
            type={type}
            onChange={onChange}
            accept={accept}
        />
        <Typography>{title}</Typography>
        <Typography variant='caption' paragraph>{data ? data.name : 'Ningún archivo seleccionado'}</Typography>
        <Button onClick={openFileSelector} color='primary' variant='outlined'>Buscar</Button>
    </Paper>
}