import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Leaflet from 'leaflet'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import markerIcon from '../../../../assets/location.svg'
import { useSelector } from 'react-redux'
import moment from 'moment'
import localization from 'moment/locale/es-mx'

import IPS from './ips.json'
import MACS from './macaddr.json'
moment.updateLocale('es-mx', localization)

let DefaultIcon = Leaflet.icon({
	iconUrl: markerIcon,
	shadowUrl: iconShadow,
	iconSize: [ 30, 40 ],
	iconAnchor: [ 15, 40 ],
	popupAnchor: [ 0, -25 ],
})
Leaflet.Marker.prototype.options.icon = DefaultIcon

const useStyles = makeStyles(theme => ({
	root: {},
	container: {
		height: '100%',
		width: '100%',
	},
}))

/**
 * A component representing the map where the markers are shown
 */
export default function Map() {
	const classes = useStyles()
	const { markers, zoomMarker } = useSelector(state => state.markers)
	const [ map, setMap ] = useState(null)
	const position = [ 0, 0 ]

	if (process.env.NODE_ENV) console.log('markers in redux', markers)

    /** Check when has to zoom in a marker */
	useEffect(
		() => {
			if (zoomMarker && map) map.flyTo(zoomMarker, 14)
		},
		[ zoomMarker, map ]
	)

	return (
		<Box className={classes.container} component='div'>
			<MapContainer
				whenCreated={map => setMap(map)}
				style={{ height: 'calc(100vh - 64px)', width: '100%' }}
				center={position}
				zoom={3}
				scrollWheelZoom={true}
			>
				<TileLayer
					attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
					url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
				/>

				{markers.map(({ name, deviceName, latitude, longitude, last_seen, device_id }, i) => {
					const ip_address = IPS[Math.floor(Math.random() * IPS.length)]
					const mac_addreess = MACS[Math.floor(Math.random() * IPS.length)]
					return (
						<Marker position={[ latitude, longitude ]} key={i}>
							<Popup>
								<Box>
									<Typography fontWeight='fontWeightMedium' variant='h6'>
										{name}
									</Typography>
									<Typography variant='caption' display='block'>
										{deviceName}
									</Typography>
									<Typography variant='caption' display='block'>
										{'Visto ' + moment(last_seen).fromNow()}
									</Typography>
									<Typography variant='caption' display='block'>
										{'ID: ' + device_id}
									</Typography>
									<Typography variant='caption' display='block'>
										{'IP: ' + ip_address}
									</Typography>
									<Typography variant='caption' display='block'>
										{'MAC: ' + mac_addreess}
									</Typography>
								</Box>
							</Popup>
						</Marker>
					)
				})}
			</MapContainer>
		</Box>
	)
}
