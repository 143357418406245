import React from 'react'
import { Box, IconButton, makeStyles, Menu, MenuItem, Paper, Popover, Typography } from '@material-ui/core'
import { DeleteRounded, EditRounded, LinkRounded, MoreVertRounded } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        maxWidth: 300,
    },
    popoverContent: {
        pointerEvents: "auto",
        padding: theme.spacing(1),
        maxWidth: 300
    },
    cardLine: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    }
}))

/**
 * A component representing a web site item
 * @param {string} url The url of the item 
 * @param {string} url The title of the item 
 */
export default function WrbSiteItem({ url, title }) {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [menu, setMenu] = React.useState(null)

    const menuItems = [
        { label: 'Abrir enlace', icon: LinkRounded, action: () => window.open(url, '_blank').focus() },
        { label: 'Editar', icon: EditRounded, action: () => { } },
        { label: 'Eliminar', icon: DeleteRounded, action: () => { } },
    ]

    /** popover open handler */
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget)
    }

    /** popover close handler */
    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    if (!url) return null

    return <Paper className={classes.root} variant='outlined'>
        <Box className={classes.cardLine}>
            <Typography align='center' variant='h6'>{title || 'Sin título'}</Typography>
            <IconButton size='small' onClick={({ currentTarget }) => setMenu(currentTarget)}><MoreVertRounded /></IconButton>
        </Box>
        <Box className={classes.cardLine}>
            <Typography aria-owns={Boolean(anchorEl) ? 'mouse-over-popover' : undefined} align='center' noWrap
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}>{url}
            </Typography>
        </Box>
        <Popover
            style={{ pointerEvents: 'none' }}
            id="mouse-over-popover"
            sx={{
                pointerEvents: 'none',
            }}
            classes={{
                paper: classes.popoverContent
            }}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
        >
            <Typography variant='caption' sx={{ p: 1 }}>{url}</Typography>
        </Popover>
        <Menu
            open={Boolean(menu)}
            anchorEl={menu}
            onClose={() => setMenu(null)}
        >
            {menuItems.map((item, i) => <MenuItem
                key={'menu-item-' + i}
                onClick={item.action}
            >
                <item.icon />
                {item.label}
            </MenuItem>)}
        </Menu>
    </Paper>
}