import { merge } from 'lodash'
import ReactApexChart from 'react-apexcharts'
// material
import { Card, CardHeader, Box } from '@material-ui/core'
//
import { BaseOptionChart } from './BaseOptionChart'

// ----------------------------------------------------------------------

/* const CHART_DATA = [
    {
        name: 'Windows',
        type: 'column',
        data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30]
    },
    {
        name: 'Android',
        type: 'area',
        data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43]
    }
] */

export default function AppNewDevicesPerDay({ data }) {
    console.log(data.map(e => e.count))

    const chartOptions = merge(BaseOptionChart(), {
        stroke: { width: [2] },
        plotOptions: { bar: { columnWidth: `11%`, borderRadius: 4 } },
        fill: { type: 'solid' },
        labels: data.map(e => e.day),
        xaxis: { type: 'datetime' },
        tooltip: {
            shared: true,
            intersect: false,
            y: {
                formatter: (y) => {
                    if (typeof y !== 'undefined') {
                        return `${y.toFixed(0)} visits`
                    }
                    return y
                }
            }
        }
    })

    return (
        <Card>
            <CardHeader title="Nuevos dispositivos" subheader="Por día" />
            <Box sx={{ p: 3, pb: 1 }} dir="ltr">
                <ReactApexChart
                    type="line"
                    series={[{
                        name: "Dispositivos",
                        type: "column",
                        data: data.map(e => e.count)
                    }]}
                    options={chartOptions}
                    height={364}
                />
            </Box>
        </Card>
    )
}
