import {combineReducers} from 'redux'
import { markers } from './markers'
import { globalAlert } from './globalAlert'
import { login } from './login'
import { feedback } from './feedback'

export default combineReducers({
    markers,
    globalAlert,
    login,
    feedback
})