import { CLOSE_ALERT, SEND_ALERT } from '../constants'

export const globalAlert = (state = {open: false, message: '', level: ''}, a) => {
    switch (a.type) {
        case SEND_ALERT:
            return ({...state, ...a.payload, open: true})
        case CLOSE_ALERT:
            return ({...state, open: false})
        default:
            return state
    }
}