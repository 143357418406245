import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -10,
		marginLeft: -10
	}
}))

export default function LoadingButton(props) {
	const classes = useStyles()

	const getIcon = () => (
		<React.Fragment>
			{props.startIcon}
			{props.loading && <CircularProgress size={20} className={classes.buttonProgress} />}
		</React.Fragment>
	)

	return (
		<Button {...props} startIcon={() => getIcon()} disabled={props.loading}>
			{props.children}
		</Button>
	)
}
