import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, Divider, Fab, TextField, Typography } from '@material-ui/core'
import { AddRounded } from '@material-ui/icons'
import NewsItem from './NewsItem'
import { useDispatch } from 'react-redux'
import { sendAlert } from '../../../actions/globalAlert/sendAlert'
import { startLoading } from '../../../actions/feedback/startLoading'
import { endLoading } from '../../../actions/feedback/endLoading'
import formatDate from '../../../utils/formatDate'
import CustomDialog from '../../../utils/CustomDialog'
import FileSelector from '../../../utils/FileSelector'
import { apiDelete, apiGet, apiPost, apiPut } from '../../../api'
import { urlNews } from '../../../api/urls'
import { encodeBase64 } from '../../../utils/encoding'
import { STATUS_OK } from '../../../constants'
import NewsContent from './NewsContent'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(2),
        flex: 1
    },
    fab: {
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        position: 'fixed'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    news: {
        margin: theme.spacing(2)
    }
}))

const fields = [
    { name: 'title', label: 'Titulo' },
    {
        name: 'type', label: 'Tipo de comunicado', select: true, selectOptions: [
            { label: 'Texto', value: 'text' },
            { label: 'Imagen', value: 'image' },
            { label: 'Video', value: 'video' },
            { label: 'Documento (PDF)', value: 'document' },
        ]
    },
]

/**
 * Represents the News page
 */
export default function News() {
    const classes = useStyles()
    const [news, setNews] = useState([])
    const [dialogData, setDialogData] = useState({ open: false, data: { type: '' }, error: false })
    const [dialogContent, setDialogContent] = useState({ open: false, news: {} })
    const dispatch = useDispatch()

    useEffect(() => {
        getNews()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getNews = () => {
        dispatch(startLoading())
        apiGet(urlNews).then(r => {
            if (r.status === STATUS_OK) {
                setNews(r.data)
            } else {
                dispatch(sendAlert("Error al obtener el listado de noticias", "error"))
            }
            dispatch(endLoading())
        }).catch(e => {
            dispatch(sendAlert("Error al obtener el listado de noticias", "error"))
            dispatch(endLoading())
        })
    }

    const onClick = news => {
        setDialogContent({ open: true, news })
    }

    const renderNews = () => {
        let lastMonth = -1
        let lastYear = -1
        return news.map((item, i) => {
            const itemMonth = new Date(item.date).getMonth()
            const itemYear = new Date(item.date).getFullYear()
            const newsItem = <NewsItem key={'newsitem-' + i} news={item} onClick={onClick} />
            if ((lastMonth !== itemMonth) || (lastYear !== itemYear)) {
                lastMonth = itemMonth
                lastYear = itemYear
                const date = formatDate(new Date(item.date))
                return <React.Fragment key={'rf-' + i} >
                    <Typography key={'t-' + i} style={{ width: '100%' }}>{date.substring(date.indexOf('/') + 1)}</Typography>
                    <Divider style={{ width: '100%' }} />
                    {newsItem}
                </React.Fragment>
            }
            return newsItem
        })
    }

    const onClose = () => {
        setDialogData({ open: false, data: { type: '' }, error: false })
    }

    const onSubmit = () => {
        const { data } = dialogData
        const api = data.edit ? apiPut : apiPost
        if (data.title && data.type && (data.content || (data.edit)) && ((data.edit && data.id) || !data.edit)) {
            const doFetch = (content) => {
                if (content) {
                    dispatch(startLoading())
                    api(urlNews, { id: data.id, title: data.title, type: data.type, content: content.target.result, edit: data.edit, date: data.date }).then(r => {
                        if (r.status === STATUS_OK) {
                            dispatch(sendAlert('Noticia guardada con éxito', 'success'))
                            getNews()
                            onClose()
                            onCloseContentDialog()
                        } else {
                            dispatch(sendAlert('Error al guardar noticia', 'error'))
                        }
                        dispatch(endLoading())
                    }).catch(r => {
                        console.log(r)
                        dispatch(sendAlert('Error al guardar noticia', 'error'))
                        dispatch(endLoading())
                    })
                } else {
                    dispatch(sendAlert('Error al cargar el contenido de la noticia', 'error'))
                }
            }
            if (['text', 'video'].includes(data.type)) {
                doFetch({ target: { result: data.content } })
            } else {
                if (data.edit && !data.content) {
                    doFetch({ target: { result: data.content } })
                } else {
                    encodeBase64(data.content, doFetch)
                }
            }
        } else {
            dispatch(sendAlert('Falta completar uno o mas campos', 'info'))
        }
    }

    const renderExtraData = () => {
        switch (dialogData.data?.type) {
            case 'image': return <FileSelector
                error={dialogData.error}
                data={dialogData.data.content}
                inputId={'image-input'}
                onChange={(e) => onChange('content', e.target.files.length ? e.target.files[0] : null)}
                openFileSelector={() => document.getElementById('image-input').click()}
                title={`Contenido ${dialogData.data.edit && !dialogData.data.content ? "(Sin cambios)" : ""}`}
                type={'file'}
                accept={'.png, .jpg'}
            />
            case 'video': return <>
                <TextField
                    name={'content'}
                    value={dialogData.data['content']}
                    label={'Video'}
                    variant='outlined'
                    margin='dense'
                    onChange={e => onChange('content', e.target.value)}
                    error={dialogData.error && !dialogData.data['content']}
                />
                <iframe
                    title='video'
                    width='520'
                    height='315'
                    allowFullScreen
                    src={dialogData.data.content?.replace('watch?v=', 'embed/').replace('.be', 'be.com/embed')}
                />
            </>
            case 'document': return <FileSelector
                error={dialogData.error}
                data={dialogData.data.content}
                inputId={'document-input'}
                onChange={(e) => onChange('content', e.target.files.length ? e.target.files[0] : null)}
                openFileSelector={() => document.getElementById('document-input').click()}
                title={`Contenido ${dialogData.data.edit && !dialogData.data.content ? "(Sin cambios)" : ""}`}
                type={'file'}
                accept={'.pdf'}
            />
            case 'text': return <TextField
                name={'content'}
                value={dialogData.data['content']}
                label={'Contenido'}
                variant='outlined'
                margin='dense'
                multiline
                rowsMax={6}
                rows={2}
                onChange={e => onChange('content', e.target.value)}
                error={dialogData.error && !dialogData.data['content']}
            />
            default: return null
        }
    }

    const onChange = (name, value) => {
        if (name === "type") {
            setDialogData(ps => ({ ...ps, data: { ...ps.data, content: undefined } }))
            // limpio el contenido de los inputs 
            let input = document.getElementById('document-input')
            if (input) {
                input.value = null
            }
            input = document.getElementById('image-input')
            if (input) {
                input.value = null
            }
        }
        setDialogData(ps => ({ ...ps, data: { ...ps.data, [name]: value } }))
    }

    const onCloseContentDialog = () => {
        setDialogContent({ open: false, news: {} })
    }

    const onEdit = news => {
        setDialogData({ open: true, data: { ...news, edit: true }, })
    }

    const onDelete = news => {
        dispatch(startLoading())
        apiDelete(urlNews, { id: news.id, type: news.type }).then(r => {
            if (r.status === STATUS_OK) {
                dispatch(sendAlert("Noticia eliminada con éxito", "success"))
                getNews()
                onCloseContentDialog()
            } else {
                dispatch(sendAlert("Algo salió mal", "error"))
            }
            dispatch(endLoading())
        }).catch(e => {
            dispatch(sendAlert("Algo salió mal", "error"))
            dispatch(endLoading())
        })
    }

    return <Box className={classes.root}>
        <Fab className={classes.fab} color='primary' variant='extended' onClick={() => setDialogData({ open: true, data: { type: '' }, error: false })}>
            <AddRounded /> Nueva noticia
        </Fab>
        <NewsContent open={dialogContent.open} news={dialogContent.news} onClose={onCloseContentDialog} onEdit={onEdit} onDelete={onDelete} />
        <CustomDialog
            title={`${dialogData.data?.edit ? 'Editar' : 'Nueva'} noticia`}
            data={dialogData.data}
            open={dialogData.open}
            onClose={onClose}
            onSubmit={onSubmit}
            fields={fields}
            error={dialogData.error}
            renderExtraData={renderExtraData}
            onChangeData={({ target: { name, value } }) => onChange(name, value)}
        />
        <Typography variant='h5' paragraph>Noticias</Typography>
        <Box className={classes.content}>
            {renderNews()}
        </Box>
    </Box>
}