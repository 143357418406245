import { ADD_MARKER, REMOVE_MARKER, ZOOM_MARKER } from '../constants'

export const markers = (state = { markers: [] }, a) => {
	switch (a.type) {
		case ADD_MARKER:
			return { ...state, markers: [ ...state.markers, a.payload ] }
		case REMOVE_MARKER:
			return { ...state, markers: state.markers.filter(e => e.device_id !== a.payload) }
        case ZOOM_MARKER:
            return {...state, zoomMarker: a.payload}
		default:
			return state
	}
}
