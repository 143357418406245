var headers = new Headers()
headers.append('Authorization', localStorage.getItem('Authorization'))

/**
 * Make a GET request
 * @param {string} url The url to fetch
 * @returns A promise with the result of the request
 */
export const apiGet = url => {
    var headers = new Headers()

    headers.append('Authorization', localStorage.getItem('Authorization'))
    return fetch(url, {
        method: 'GET',
        headers
    })
        .then(v => v.json())
        .then(data => data)
        .catch(r => {
            //console.log(r)
            return { status: 'err', info: 'in api file', data: r }
        })
}

/**
 * Make a PUT request
 * @param {string} url The url to fetch
 * @param {object} obj The object with data to send
 * @returns A promise with the result of the request
 */
export const apiPut = (url, obj, contentType = 'application/json') => {
    var headers = new Headers()

    headers.append('Authorization', localStorage.getItem('Authorization'))
    headers.append('content-type', contentType)
    return fetch(`${url}`, {
        method: 'PUT',
        body: JSON.stringify(obj),
        headers
    })
        .then(v => v.json())
        .then(r => {
            if (r.error) {
                return Promise.reject(r.validation)
            }
            return r
        })
        .catch(r => {
            //console.log(r)
            return { status: 'err', info: 'in api file', data: r }
        })
}

/**
 * Make a DELETE request
 * @param {string} url The url to fetch
 * @param {object} obj The object with data to send
 * @returns A promise with the result of the request
 */
export const apiDelete = (url, obj, contentType = 'application/json') => {
    var headers = new Headers()

    headers.append('Authorization', localStorage.getItem('Authorization'))
    headers.append('content-type', contentType)
    return fetch(`${url}`, {
        method: 'DELETE',
        body: JSON.stringify(obj),
        headers
    })
        .then(v => v.json())
        .then(r => {
            if (r.error) {
                return Promise.reject(r.validation)
            }
            return r
        })
        .catch(r => {
            //console.log(r)
            return { status: 'err', info: 'in api file', data: r }
        })
}

/**
 * Make a POST request
 * @param {string} url The url to fetch
 * @param {object} obj The object with data to send
 * @returns A promise with the result of the request
 */
export const apiPost = (url, obj, contentType = 'application/json') => {
    var headers = new Headers()

    headers.append('Authorization', localStorage.getItem('Authorization'))
    headers.append('content-type', contentType)
    return fetch(`${url}`, {
        method: 'POST',
        body: JSON.stringify(obj),
        headers
    })
        .then(v => v.json())
        .then(r => {
            if (r.error) {
                return Promise.reject(r.validation)
            }
            return r
        })
        .catch(r => {
            //console.log(r)
            return { status: 'err', info: 'in api file', data: r }
        })
}

/**
 * Make a GET request with query parameters
 * @param {string} url The url to fetch
 * @param {object} params The object with the parameters
 * @returns A promise with the result of the request
 */
export const apiGetWithParams = (url, params) => {
    var headers = new Headers()

    headers.append('Authorization', localStorage.getItem('Authorization'))
    var toFetch = new URL(url)
    toFetch.search = new URLSearchParams(params)

    return fetch(toFetch, { method: 'GET', headers }).then(r => r.json()).catch(r => ({ status: 'err', info: 'in api file', data: r }))
}
