import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, makeStyles, MenuItem, TextField } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
    }
}))

/**
 * A component representing a custom form dialog
 * @param {object} data The values of fields
 * @param {string} title The dialog title
 * @param {function} onClose onClose function
 * @param {function} onSubmit onSubmit function 
 * @param {function} onChangeData TextField onChange function
 * @param {object[]} fields The fields to render. Contains an object with name and label properties
 * @param {boolean} open The dialog open state
 * @param {boolean} error Display a red color in the incorrect TextFields data when submit
 * @param {function} renderExtraData The extra data to render in the form, that is not a TextField
 */
export default function CustomDialog({ title, onClose, onSubmit, data, onChangeData, fields, open, error, renderExtraData = () => { } }) {
    const classes = useStyles()

    if (!open) return null

    console.log(data)

    return <Dialog className={classes.root} open={open}>
        <DialogTitle>{title}</DialogTitle>
        <Divider />
        <DialogContent className={classes.content}>
            {fields.map((field, i) => <TextField
                key={'tf-' + i}
                name={field.name}
                value={data[field.name]}
                label={field.label}
                variant='outlined'
                margin='dense'
                onChange={onChangeData}
                error={error && !data[field.name]}
                select={field.select}
            >
                {field.select ? 
                    field.selectOptions.map((option, j) => <MenuItem 
                        key={`mi-${i} ${j}`} 
                        button
                        value={option.value}
                    >
                        {option.label}
                    </MenuItem>) 
                    : null
                }
            </TextField>)}
            {renderExtraData()}
        </DialogContent>
        <Divider />
        <DialogActions>
            <Button color='secondary' onClick={onClose}>Cancelar</Button>
            <Button color='primary' onClick={onSubmit} variant='contained'>Guardar</Button>
        </DialogActions>
    </Dialog >
}