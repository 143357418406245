import React, { useContext, createContext, useState } from "react";

const authContext = createContext();

function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

function useAuth() {
  return useContext(authContext);
}

function useProvideAuth() {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const hasToken = () => {
    return Boolean(localStorage.getItem(process.env.REACT_APP_LOCAL_STORAGE_USER_TOKEN));
  };

  const verifyToken = async () => {
    setLoading(true);
    setAuthenticated(false);
    console.log("Verifying token");
    //await sleep(1000);
    setAuthenticated(true);
    setLoading(false);
    return true;
  };

  return {
    hasToken,
    verifyToken,
    authenticated,
    loading,
  };
}

export { ProvideAuth, useAuth };
