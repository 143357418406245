import React, { useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, makeStyles, Paper, Typography } from '@material-ui/core'
import { InfoRounded } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        maxWidth: 300,
    },
    item: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    }
}))

/* 
    app: {
        id
        titulo
        plataforma
        packageName
        versiones [
            id
            numero
            extra info ---> al hacer click en detalles {
                cantidad de dispositivos que usan esta version
            }
            apkUrl
        ]
    }
*/

/**
 * A component representing an App Item
 * @param {object} app Object with the app data. Must have title, versions, platform and packageName
 */
export default function AppItem({ app }) {
    const classes = useStyles()
    const [extraData, setExtraData] = useState({ open: false, data: {} })
    const { title, versions, platform, packageName } = app

    /**
     * Get version extra data
     * @param {string} vers Version to fetch data
     */
    const onInfoClick = vers => {
        // todo fetch version extra data 
        setExtraData({ open: true, data: { ...vers, id: vers.id, devices: 143982 } })
    }

    /** Closes the app info dialog dialog */
    const closeDialog = () => {
        setExtraData({ open: false, data: {} })
    }

    return <Paper variant='outlined' className={classes.root}>
        <Typography paragraph variant='h6'>{title}</Typography>
        <Typography>Plataforma: {platform}</Typography>
        <Typography paragraph>Paquete: {packageName}</Typography>
        {versions.map((v, i) => <React.Fragment key={'fragment-' + i}>
            <Divider />
            <Box key={'item-' + i} className={classes.item}>
                <Typography key={'version-' + i}>{v.number}</Typography>
                <IconButton key={'iconbutton-' + i} size='small' onClick={() => onInfoClick(v)}><InfoRounded key={'icon-' + i} color='primary' /></IconButton>
            </Box>
        </React.Fragment>
        )}
        <Dialog open={extraData.open} onClose={closeDialog}>
            <DialogTitle title={`${title} - ${extraData.data.number}`}>{title} - {extraData.data.number}</DialogTitle>
            <DialogContent>
                <Typography>Dispositivos utilizando esta version: {extraData.data.devices}</Typography>
            </DialogContent>
            <DialogActions>
                <Button variant='contained' color='primary' onClick={closeDialog}>Salir</Button>
            </DialogActions>
        </Dialog>
    </Paper>
}