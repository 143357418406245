// redux

export const ADD_MARKER = 'addMarker'
export const REMOVE_MARKER = 'removeMarker'
export const ZOOM_MARKER = 'zoomMarker'
export const SEND_ALERT = 'sendAlert'
export const CLOSE_ALERT = 'closeAlert'
export const SET_LOGIN = 'setLogin'
export const LOGOUT = 'logout'
export const SET_DRAWER_WIDTH = 'setDrawerWidth'
export const START_LOADING = 'startLoading'
export const END_LOADING = 'endLoading'

// styles

export const drawerWidth = 240
export const drawerClosed = 56

// api responses

export const STATUS_OK = 'OK'