import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import moment from 'moment'
import localization from 'moment/locale/es-mx'
import { /* Popover, */ TextField } from '@material-ui/core'
import { apiPost } from '../../../../api'
import { urlLockDevice } from '../../../../api/urls'
import { sendAlert } from '../../../../actions/globalAlert/sendAlert'
import { useDispatch } from 'react-redux'
import { LockOpenRounded, LockRounded } from '@material-ui/icons'
import { addMarker } from '../../../../actions/markers/addMarker'
import { removeMarker } from '../../../../actions/markers/removeMarker'
import { setZoomMarker } from '../../../../actions/markers/setZoomMarker'

moment.updateLocale('es-mx', localization)

const useStyles = makeStyles(theme => ({
	listItem: {
		marginBottom: 12,
		marginTop: 12,
	},
	moreOptionsButton: {
		margin: 'auto 0px',
	},
	buttonLock: {
		fontWeight: 'bold',
	},
	deviceIcon: {
		color: isLocked => (isLocked ? theme.palette.error.main : theme.palette.success.main),
	},
	popover: {
		padding: theme.spacing(1),
	},
}))


/**
 * Represents a device item in the device accordion
 * @param device Data from device to show 
 * @param checked The checked status of this device 
 * @param doRefresh Function for refresh devices 
 */
export default function DeviceInfo({ device, checked, doRefresh }) {
	const isLocked = device.is_locked
	const is_lockable = device.is_lockable
	const classes = useStyles(isLocked)
	const [ open, setOpen ] = useState(false)
	/* const [ anchorEl, setAnchorEl ] = useState(null) */
	const [ confirmar, setConfirmar ] = useState('')
	const [ openConfirmationDialog, setOpenConfirmationDialog ] = useState(false)
	const dispatch = useDispatch()
	const labelId = `checkbox-list-label-${device.device_id}`

	const handleToggle = (device, checked) => () => {
		if (device.latitude && device.latitude) {
			if (checked) dispatch(addMarker(device))
			else dispatch(removeMarker(device.device_id))
		} else dispatch(sendAlert('Ubicación no disponible (el dispositivo nunca registró su ubicación)', 'error'))
	}

	const changeDeviceLockState = () => {
		if (confirmar.toLowerCase() === 'confirmar')
			apiPost(urlLockDevice, { deviceId: device.device_id, locked: !isLocked }).then(r => {
				if (r.status === 'OK') {
					dispatch(sendAlert(`Dispositivo ${isLocked ? 'desbloqueado' : 'bloqueado'} con éxito`, 'success'))
					doRefresh()
					setOpenConfirmationDialog(false)
					setConfirmar('')
					setOpen(false)
				} else dispatch(sendAlert(`Error al ${isLocked ? 'desbloquear' : 'bloquear'} el dispositivo`, 'error'))
			})
		else dispatch(sendAlert('Escriba la palabra CONFIRMAR para bloquear el dispositivo', 'warning'))
	}

	return (
		<Box display='flex' flexDirection='row' alignItems='space-between' justifyContent='center'>
			<ListItem
				className={classes.listItem}
				key={'device' + device.device_id}
				role={undefined}
				dense
				button
				onClick={handleToggle(device, !checked)}
			>
				<ListItemIcon className={is_lockable ? classes.deviceIcon : undefined}>
					{isLocked ? <LockRounded /> : <LockOpenRounded />}
				</ListItemIcon>
				<ListItemText
					id={labelId}
					primary={device.name}
					secondary={
						<Box>
							<Typography variant='caption' display='block'>
								{device.deviceName}
							</Typography>
							<Typography variant='caption' display='block'>
								{device.last_seen ? (
									`Visto hace ${moment(device.last_seen).fromNow()}`
								) : (
									'Este dispositivo nunca fué encendido'
								)}
							</Typography>
							<Typography variant='caption' display='block'>
								{`ID: ${device.device_id}`}
							</Typography>
						</Box>
					}
				/>
				<ListItemSecondaryAction>
					<Checkbox
						color='primary'
						edge='start'
						onClick={handleToggle(device, !checked)}
						checked={checked}
						tabIndex={-1}
						disableRipple
						inputProps={{
							'aria-labelledby': labelId,
						}}
					/>
				</ListItemSecondaryAction>
			</ListItem>
			<IconButton
				aria-label='device-menu'
				className={classes.moreOptionsButton}
				aria-controls='options-menu'
				aria-haspopup='true'
				onClick={e => setOpen(e.currentTarget)}
			>
				<MoreVertIcon fontSize='small' />
			</IconButton>
{/* 			<Popover
				id='mouse-over-popover'
				sx={{
					pointerEvents: 'none',
				}}
				open={Boolean(anchorEl)}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				onClose={() => setAnchorEl(null)}
				disableRestoreFocus
			>
				<Typography variant='subtitle1' className={classes.popover}>El dispositivo tiene una version desactualizada</Typography>
			</Popover> */}
			<Menu
				id='options-menu'
				anchorEl={open}
				keepMounted
				open={Boolean(open)}
				onClose={() => setOpen(null)}
				transformOrigin={{ vertical: 'top', horizontal: 'left' }}
			>
				<MenuItem disabled={!is_lockable} onClick={() => setOpenConfirmationDialog(true)}>
					{!is_lockable? 'No se ha actualizado este ' : isLocked ? 'Desbloquear' : 'Bloquear'} dispositivo
				</MenuItem>
				<MenuItem
					onClick={() => {
						if (!checked) dispatch(addMarker(device))
						dispatch(setZoomMarker([ device.latitude, device.longitude ]))
					}}
				>
					Ubicar en mapa
				</MenuItem>
			</Menu>
			<Dialog maxWidth='xs' open={openConfirmationDialog}>
				<DialogTitle id='confirmation-dialog-title'>Confirmación de bloqueo</DialogTitle>
				<DialogContent dividers>
					<Typography paragraph>
						Para bloquear el dispositivo con ID: {device.device_id}, complete con la palabra CONFIRMAR{' '}
					</Typography>
					<TextField
						margin='dense'
						variant='filled'
						color='inherit'
						value={confirmar}
						onChange={({ target: { value } }) => setConfirmar(value)}
						placeholder='CONFIRMAR'
						fullWidth
					/>
				</DialogContent>
				<DialogActions>
					<Button autoFocus onClick={() => setOpenConfirmationDialog(false)}>
						Cancelar
					</Button>
					<Button
						className={classes.buttonLock}
						variant='contained'
						color={isLocked ? 'primary' : 'secondary'}
						disabled={confirmar.toLowerCase() !== 'confirmar'}
						onClick={() => changeDeviceLockState()}
					>
						{isLocked ? 'Desbloquear' : 'Bloquear'}
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	)
}
