import { SET_LOGIN, LOGOUT } from '../constants'

export const login = (state = {}, a) => {
	switch (a.type) {
		case SET_LOGIN:
            const { userData } = a.payload
			localStorage.setItem(process.env.REACT_APP_LOCAL_STORAGE_USER_TOKEN, userData.token)
            return ({...userData})
		case LOGOUT:
            localStorage.removeItem(process.env.REACT_APP_LOCAL_STORAGE_USER_TOKEN)
			return ({})
		default:
			return state
	}
}
