import React, {useState} from 'react'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

import SearchForm from './SearchForm'
import SearchResult from './SearchResult'

const useStyles = makeStyles((theme) => ({
    container: {
        width: 'calc(100% - 40px)',
        height: 'calc(100% - 40px)',
        overflowY: 'auto',
        padding: 20,
    },
}))

/**
 * Represents the seach custom component
 */
export default function Search() {
    var theme = useTheme()
    const classes = useStyles(theme)

    const [queryResult, setQueryResult] = useState([])

    return (
        <Box className={classes.container}>
           <SearchForm {...{setQueryResult}} /> 
          <SearchResult {...{queryResult}} />
        </Box>
    )
}
