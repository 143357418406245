import { SET_DRAWER_WIDTH, drawerWidth, START_LOADING, END_LOADING } from '../constants'

export const feedback = (state = { drawerWidth, loading: 0 }, a) => {
    switch (a.type) {
        case SET_DRAWER_WIDTH:
            return ({ ...state, drawerWidth: a.payload })
        case START_LOADING:
            return ({ ...state, loading: state.loading + 1 })
        case END_LOADING:
            return ({ ...state, loading: state.loading - 1 })
        default:
            return state
    }
}