import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import List from '@material-ui/core/List'
import Pagination from '@material-ui/lab/Pagination'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'

import { useSelector } from 'react-redux'
import DeviceInfo from './DeviceInfo'
import { apiGetWithParams } from '../../../../api'
import { urlDevices } from '../../../../api/urls'

const useStyles = makeStyles(theme => ({
	root: {
		width: '100%',
	},
	heading: {
		fontSize: theme.typography.pxToRem(15),
		fontWeight: theme.typography.fontWeightRegular,
	},
	accordionSummaryContent: {
		margin: '0 !important',
	},
	accordionSummary: {
		minHeight: 'unset !important',
	},
	accordionDetails: {
		paddingTop: 0,
		paddingBottom: 0,
	},
	pagiationController: {
		justifyContent: 'center',
		paddingBottom: theme.spacing(2),
	},
}))

/**
 * Represents list of a group of devices
 * @param name The group name
 * @param id The group id
 * @param initialDeviceCount The device count 
 * @returns 
 */
export default function DeviesAccordion({ name, id, initialDeviceCount }) {
	const classes = useStyles()

	const pageSize = 10

	const { markers } = useSelector(state => state.markers)
	const [ devices, setDevices ] = useState(undefined)
	const [ deviceCount, setDeviceCount ] = useState(initialDeviceCount)
	const [ loading, setLoading ] = useState(false)
	const [ selectedPage, setSelectedPage ] = useState(0)
	const [ refresh, setRefresh ] = useState(0)

	const getDevices = () => {
		setLoading(true)
		apiGetWithParams(urlDevices, { group: id, pageSize, page: selectedPage })
			.then(r => {
				if (r.status === 'OK') {
					setDevices(ps => ({ ...ps, [selectedPage]: r.data }))
					setDeviceCount(r.meta.userCount)
				} else {
					console.log('error al obtener devices del grupo ' + id + ' pagina ' + selectedPage)
				}
				setLoading(false)
			})
			.catch(_ => setLoading(false))
	}

	useEffect(
		() => {
			getDevices()
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[ selectedPage, refresh ]
	)

	const doRefresh = () => {
		setRefresh(refresh + 1)
	}

	const onExpanded = () => {
		getDevices()
	}

	return (
		<Accordion
			onChange={(_, expanded) => {
				if (expanded) onExpanded()
			}}
		>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls='panel1a-content'
				id='panel1a-header'
				classes={{
					content: classes.accordionSummaryContent,
					root: classes.accordionSummary,
				}}
			>
				<Typography className={classes.heading}>{name}</Typography>
			</AccordionSummary>
			<AccordionDetails className={classes.accordionDetails}>
				<List className={classes.root}>
					{devices ? devices[selectedPage] && devices[selectedPage].length ? (
						devices[selectedPage].map((device, index) => (
							<React.Fragment>
								<DeviceInfo
									key={'di-' + index}
									{...{
										device,
										doRefresh,
										checked: Boolean(markers.find(d => d.device_id === device.device_id)), // devuelve el objeto entero si lo encuentra sino undefined
									}}
								/>
								<Divider variant='inset' component='li' />
							</React.Fragment>
						))
					) : (
						<Typography>{loading ? 'Cargando...' : 'No hay dispositivos para mostrar'}</Typography>
					) : (
						<Box
							style={{
								height: 75,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							<Typography variant='caption' display='block'>
								{loading ? 'Cargando...' : 'No hay dispositivos para mostrar'}
							</Typography>
						</Box>
					)}
				</List>
			</AccordionDetails>
			<Pagination
				classes={{ ul: classes.pagiationController }}
				count={Math.ceil(deviceCount / pageSize)}
				size='small'
				siblingCount={0}
				onChange={(_, newPage) => setSelectedPage(newPage - 1)}
			/>
		</Accordion>
	)
}
