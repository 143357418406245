import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import DevicesAccordion from './DevicesAccordion'

import { Typography } from '@material-ui/core'
import { apiGet } from '../../../../api'
import { urlGroups } from '../../../../api/urls'

const useStyles = makeStyles(theme => ({
	container: {
		width: 'calc(100% - 40px)',
		paddingTop: 20,
		paddingBottom: 20,
		paddingRight: 20,
		paddingLeft: 20
	}
}))

/**
 * Represents the group list of devices
 */
export default function GroupsDrawerContent() {
	const classes = useStyles()
    const [ groups, setGroups] = useState(undefined)

    const openWs = () => {

    }

	useEffect(()=>{
        openWs()
        apiGet(urlGroups).then(r => {
            if (r.status === 'OK') {
                setGroups(r.data)
            }
        })
    }, [])

		return  groups && groups.length? 
			<Box className={classes.container}>
				{groups.map((v, i) => (
					<DevicesAccordion key={'da-'+i} id={v.id} initialDeviceCount={v.deviceCount} name={v.name} />
				))}
			</Box>
		:
		<Typography variant='caption'>No hay nada para mostrar aún</Typography>
	
}
