import React, { useEffect, useState } from 'react'
import { Box, Fab, makeStyles, MenuItem, TextField, Typography } from '@material-ui/core'
import AppItem from './AppItem'
import { AddRounded } from '@material-ui/icons'
import CustomDialog from '../../../utils/CustomDialog'
import { useDispatch } from 'react-redux'
import { sendAlert } from '../../../actions/globalAlert/sendAlert'
import FileSelector from '../../../utils/FileSelector'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2)
    },
    top: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-end',
        marginBottom: theme.spacing(2)
    },
    content: {
        flex: 1,
        display: 'flex',
        alignContent: 'flex-start',
        flexWrap: 'wrap',
        alignItems: 'stretch'
    },
    searchTextField: {
        marginLeft: theme.spacing(2)
    },
    fab: {
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        position: 'fixed'
    },
    installerContainer: {
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    }
}))

const appsExample = [
    {
        id: 1, title: 'Aplication 1', platform: 0, packageName: 'com.wedrim.aplication1', versions: [
            { id: 1, apkUrl: 'https://google.com', number: '0.0.1' },
            { id: 2, apkUrl: 'https://google.com', number: '0.0.2' }
        ]
    },
    {
        id: 1, title: 'Aplication 2', platform: 0, packageName: 'com.wedrim.aplication2', versions: [
            { id: 1, apkUrl: 'https://google.com', number: '0.0.1' },
            { id: 2, apkUrl: 'https://google.com', number: '0.0.2' },
            { id: 3, apkUrl: 'https://google.com', number: '0.1.0' }
        ]
    },
    {
        id: 1, title: 'Aplication 1', platform: 1, packageName: 'com.wedrim.aplication1.win', versions: [
            { id: 1, apkUrl: 'https://google.com', number: '0.0.1' },
            { id: 2, apkUrl: 'https://google.com', number: '0.0.2' }
        ]
    },
]

const platforms = [{ id: 0, label: 'Android' }, { id: 1, label: 'Windows' }]
const installerInputId = 'installer-input'
const fields = [
    { name: 'title', label: 'Nombre' },
    { name: 'packageName', label: 'Nombre del paquete' },
    { name: 'version', label: 'Numero de version' },
]

/**
 * A component representing the app management screen
 */
export default function AppManagement() {
    const [search, setSearch] = useState('')
    const [dialogData, setDialogData] = useState({ open: false, data: {}, error: false })
    const classes = useStyles(dialogData.error)
    const [platform, setPlatform] = useState(platforms[0].id)
    const [apps, setApps] = useState([])
    const dispath = useDispatch()

    useEffect(() => {
        setApps(appsExample)
    }, [])

    /** Closes the new/edit dialog */
    const onClose = () => {
        setDialogData({ open: false, data: {} })
    }

    /** Submit the new/edit form */
    const onSubmit = () => {
        const { title, packageName, version, installer, platform } = dialogData.data
        if (title && packageName && version && installer && platform)
            // TODO send data to backend
            onClose()
        else {
            dispath(sendAlert('Falta completar uno o mas campos', 'info'))
            setDialogData(ps => ({ ...ps, error: true }))
        }
    }

    /** Open a sistem dialog to select a file */
    const openSelectInstaller = () => {
        document.getElementById(installerInputId).click()
    }

    /** Render extra data in the form dialog */
    const renderExtraData = () => {
        if (dialogData.data.edit) return <Typography>No es posible editar el instalador</Typography>
        return <React.Fragment>
            <TextField
                variant='outlined'
                name='platform'
                select
                value={dialogData.data.platform}
                label='Plataforma'
                margin='dense'
                onChange={({ target: { value, name } }) => onChange(name, value)}
                error={dialogData.error & !dialogData.data.platform}
            >
                {platforms.map((plat, i) => <MenuItem key={'menuitem-' + i} value={plat.id}>
                    {plat.label}
                </MenuItem>)}
            </TextField>
            <FileSelector
                error={dialogData.error}
                data={dialogData.data.installer}
                inputId={installerInputId}
                onChange={(e) => onChange('installer', e.target.files.length ? e.target.files[0] : null)}
                openFileSelector={openSelectInstaller}
                title='Instalador'
                type={'file'}
                accept={'.apk, .exe'}
            />
        </React.Fragment>
    }

    /**  */
    const newVersion = data => {

    }

    /** onChange function for the form dialog */
    const onChange = (name, value) => setDialogData(ps => ({ ...ps, data: { ...ps.data, [name]: value } }))

    return <Box className={classes.root}>
        <Fab className={classes.fab} color='primary' variant='extended' onClick={() => setDialogData({ open: true, data: {} })}>
            <AddRounded /> Agregar app
        </Fab>
        <CustomDialog
            title={`${dialogData.data.edit ? 'Editar' : 'Nueva'} ${dialogData.data.version ? 'versión' : 'App'}`}
            data={dialogData.data}
            open={dialogData.open}
            onClose={onClose}
            onSubmit={onSubmit}
            fields={fields}
            error={dialogData.error}
            renderExtraData={renderExtraData}
            onChangeData={({ target: { name, value } }) => onChange(name, value)}
        />
        <Box className={classes.top}>
            <Typography variant='h5' style={{ marginRight: 16 }}>Gestión de aplicaciones</Typography>
            <TextField
                name='platform'
                value={platform}
                hiddenLabel='Plataforma'
                variant='outlined'
                margin='dense'
                select
                onChange={({ target: { value } }) => setPlatform(value)}
            >
                {platforms.map((plat, i) => <MenuItem key={'menuitem-' + i} value={plat.id}>
                    {plat.label}
                </MenuItem>)}
            </TextField>
            <TextField
                className={classes.searchTextField}
                name='seach'
                value={search}
                label='Buscar'
                variant='outlined'
                margin='dense'
                onChange={({ target: { value } }) => setSearch(value)}
            />
        </Box>
        <Box className={classes.content}>
            {apps.map((app, i) => (app.title.toLowerCase().indexOf(search.toLowerCase()) !== -1 ||
                app.packageName.toLowerCase().indexOf(search.toLowerCase()) !== -1) && app.platform === platform ?
                <AppItem key={'app-item-' + i} app={app} platforms={platforms} newVersion={data => newVersion(data)} /> : null)
            }
        </Box>
    </Box>
}