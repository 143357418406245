import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import IconButton from '@material-ui/core/IconButton'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { menu } from './menu'
import { drawerClosed, drawerWidth } from '../../../constants'

const useStyles = makeStyles(theme => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		overflowX: 'hidden',
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: drawerClosed,
		[theme.breakpoints.up('sm')]: {
			width: drawerClosed,
		},
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
}))

export default function MenuDrawer({ open, handleDrawerClose, setDrawerContent }) {
	const classes = useStyles()

	return (
		<Drawer
			variant='permanent'
			className={clsx(classes.drawer, {
				[classes.drawerOpen]: open,
				[classes.drawerClose]: !open,
			})}
			classes={{
				paper: clsx({
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				}),
			}}
		>
			<div className={classes.toolbar}>
				<IconButton onClick={handleDrawerClose}>
					{' '}
					<ChevronLeftIcon />
				</IconButton>
			</div>
			<Divider />
			{menu.map((subMenu, j) => (
				<React.Fragment key={'rf-' + j}>
					<List key={'l-' + j}>
						{subMenu.map((menuItem, i) => (
							<Link key={'l-' + i} to={menuItem.link}>
								<ListItem key={'li-' + i} button>
									<ListItemIcon key={'lii-' + i}>{menuItem.icon}</ListItemIcon>
									<ListItemText
										key={'lit-' + i}
										style={{ display: 'flex', flex: '1 1 auto' }}
										disable
										primary={menuItem.text}
										primaryTypographyProps={{
											variant: 'body2',
											style: { display: 'inline-block' },
										}}
									/>
								</ListItem>
							</Link>
						))}
					</List>
					{menu.length - 1 > j && <Divider />}
				</React.Fragment>
			))}
		</Drawer>
	)
}
