import React from 'react'
import { SearchRounded, GroupRounded, DashboardRounded, BlockRounded, GetAppRounded, ReceiptRounded } from '@material-ui/icons'

// Cada arreglo de objetos es una sección en el drawer
export const menu = [
	[ { text: 'Tablero', icon: <DashboardRounded />, link: '/dashboard' } ],
	[
		{ text: 'Dispositivos', icon: <GroupRounded />, link: '/location' },
		{ text: 'Buscar', icon: <SearchRounded />, link: '/location/search' },
		{ text: 'Gestión de Apps', icon: <GetAppRounded />, link: '/app-management' },
		{ text: 'Bloqueo de WebSites', icon: <BlockRounded />, link: '/blocked-websites' },
		{ text: 'Gestión de noticias', icon: <ReceiptRounded />, link: '/news-management' },
	],
]
