import React from 'react'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import { Card, List, ListSubheader } from '@material-ui/core'
import { useSelector } from 'react-redux'

import DeviceInfo from './DeviceInfo'

const useStyles = makeStyles(theme => ({
	card: {
		minHeight: 100,
		marginTop: theme.spacing(2),
		paddingLeft: theme.spacing(1),
	},
}))

/**
 * Represents the list with results of search query
 * @param queryResult The result of the query in SearchForm component
 */
export default function SearchResult({ queryResult }) {
	var theme = useTheme()
	const classes = useStyles(theme)

	const { markers } = useSelector(state => state.markers)

	return (
		<React.Fragment>
			<Card className={classes.card} variant='outlined'>
				<List className={classes.root}>
					{queryResult.length ? (
						<React.Fragment>
							<ListSubheader>Resultados</ListSubheader>
							{queryResult.map(device => (
								<DeviceInfo
									key={`device-${device.device_id}`}
									device={device}
									checked={Boolean(markers.find(d => d.device_id === device.device_id))}
								/>
							))}
						</React.Fragment>
					) : (
						<ListSubheader>No hay resultados para mostrar</ListSubheader>
					)}
				</List>
			</Card>
		</React.Fragment>
	)
}
