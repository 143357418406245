import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { useSelector, useDispatch } from 'react-redux'
import { closeAlert } from '../../actions/globalAlert/closeAlert'

function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />
}

export default function GlobalAlert() {
	const dispatch = useDispatch()
	const open = useSelector(state => state.globalAlert.open)
	const message = useSelector(state => state.globalAlert.message)
	const level = useSelector(state => state.globalAlert.level)

    const handleClose = () => dispatch(closeAlert())

	return (
			<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
				<Alert onClose={handleClose} severity={level}>
					{message}
				</Alert>
			</Snackbar>
	)
}
