import React from 'react'
import { Route, Switch } from 'react-router'

import Map from './Map'
import Groups from './Content/Groups'
import Search from './Content/Search'
import { Box, Card, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%'
    },
    sidebar: {
        width: '30%',
        minWidth: 375,
        maxHeight: '100%',
        overflowY: 'auto'
    },
    map: {
        flex: 1
    }
})

export default function Location() {
    const classes = useStyles()
	return (
		<Box className={classes.root}>
			<Card className={classes.sidebar} elevation={1}>
				<Switch>
					<Route path='/location/search'>
						<Search />
					</Route>
					<Route path='/location'>
						<Groups />
					</Route>
				</Switch>
			</Card>
			<Box className={classes.map}>
				<Map />
			</Box>
		</Box>
	)
}
