import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, makeStyles } from '@material-ui/core'
import { urlNewsResources } from '../../../api/urls'


const useStyles = makeStyles(theme => ({
    dialogContentText: {
        whiteSpace: "pre-line"
    }
}))

export default function NewsContent({ news, open, onClose, onEdit, onDelete }) {
    const classes = useStyles()

    const documentClick = () => {
        window.open(urlNewsResources + news.id)
    }

    const renderContent = () => {
        switch (news.type) {
            case 'text':
                return <DialogContentText className={classes.dialogContentText}>{news.content}</DialogContentText>
            case 'image':
                return <img alt='news-content' width={'100%'} src={urlNewsResources + news.id} />
            case 'video':
                return <iframe
                    title='video'
                    width='520'
                    height='315'
                    allowFullScreen
                    src={news.content?.replace('watch?v=', 'embed/').replace('.be', 'be.com/embed')}
                />
            case 'document':
                return <Button variant='outlined' color='primary' onClick={documentClick}>Descargar documento</Button>
            default:
                return null
        }
    }

    return <Dialog className={classes.root} open={open} onClose={onClose}>
        <DialogTitle>{news.title}</DialogTitle>
        <Divider />
        <DialogContent>
            {renderContent()}
        </DialogContent>
        <Divider />
        <DialogActions>
            <Button onClick={onClose}>Salir</Button>
            <Button variant='contained' color='secondary' onClick={() => onDelete(news)}>Eliminar</Button>
            <Button variant='contained' color='primary' onClick={() => onEdit(news)}>Editar</Button>
        </DialogActions>
    </Dialog>
}