import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'
import Button from '@material-ui/core/Button'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import MenuDrawer from './MenuDrawer'
import { useDispatch } from 'react-redux'
import { logout } from '../../../actions/login/logout'
import { drawerClosed, drawerWidth } from '../../../constants'
import { setDrawerWidth } from '../../../actions/feedback/setDrawerWidth'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
	},
	toolbar: {
		paddingRight: 24, // keep right padding when drawer closed
	},
	appBar: {
		backgroundColor: theme.palette.primary.dark,
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create([ 'width', 'margin' ], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create([ 'width', 'margin' ], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
		color: 'white',
	},
	menuButtonHidden: {
		display: 'none',
	},
	title: {
		flexGrow: 1,
		color: 'white',
		fontWeight: 'bold',
	},

	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto',
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
	},
	fixedHeight: {
		height: 240,
	},
	drawer: {
		width: 375,
		flexShrink: 0,
	},
	drawerPaper: {
		position: 'relative',
		width: 375,
	},
	drawerContainer: {
		paddingTop: 20 + 64,
		paddingLeft: 20,
		paddingRight: 20,
		paddingBottom: 20,
	},
}))

export default function NavBar() {
	const classes = useStyles()
	const [ open, setOpen ] = useState(false)
	const dispatch = useDispatch()
    
	useEffect(()=>{
		dispatch(setDrawerWidth(open? drawerWidth : drawerClosed))
	},[dispatch, open])

	const handleDrawerOpen = () => {
		setOpen(true)
	}
	const handleDrawerClose = () => {
		setOpen(false)
	}

	return (
		<React.Fragment>
			<AppBar position='absolute' className={clsx(classes.appBar, open && classes.appBarShift)}>
				<Toolbar className={classes.toolbar}>
					<IconButton
						edge='start'
						color='inherit'
						aria-label='open drawer'
						onClick={handleDrawerOpen}
						className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
					>
						<MenuIcon />
					</IconButton>
					<Typography component='h1' variant='h6' color='inherit' noWrap className={classes.title}>
						Marauders
					</Typography>
					<Button
						style={{ color: 'white', fontWeight: 'bold' }}
						startIcon={<ExitToAppIcon />}
						onClick={e => {
							dispatch(logout())
						}}
					>
						Salir
					</Button>
				</Toolbar>
			</AppBar>
			<MenuDrawer
				{...{
					open,
					handleDrawerClose,
				}}
			/>
		</React.Fragment>
	)
}
